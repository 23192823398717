<template>
<div>
  <v-img :src="require('@/assets/notebook.png')" style="width:100%">
    <v-layout fill-height align-center>
      <v-container>
        <v-row align="center" justify="center" class="white--text">
          <v-col cols="12">
            <div
              :class="{'display-2 font-weight-bold ': $vuetify.breakpoint.smAndUp, 'title font-weight-bold': $vuetify.breakpoint.smAndDown}"
            >Daniel Ring</div>
            <div
              :class="{'headline': $vuetify.breakpoint.smAndUp, 'subtitle-1': $vuetify.breakpoint.smAndDown}"
            >Ihr Rechtsanwalt in Waidhaus</div>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>
  </v-img>

  <v-container>
    <v-layout wrap class="mt-5" align-center>
        <v-flex xs12>
          <div class="display-1 font-weight-bold" ref="steuerrecht">Was versteht man unter einer Erstberatung?</div>
        </v-flex>
        <v-flex xs12 mt-5>
          <div
            class="body-1 mt-3"
          >
          <p align="justify">
          Erstberatung meint ein erstes Gespräch über ein bestimmtes Thema mit dem Rechtsanwalt Ihres Vertrauens. Das kann, muss aber nicht ein persönliches Gespräch sein; es kann genauso gut telefonisch oder per E-Mail-Korrespondenz stattfinden. Im Erstberatungsgespräch erhalten Sie erstmals Informationen zu einer Rechtsfrage. Für die Entstehung der Erstberatungsgebühr ist es nicht erforderlich, dass der Anwalt eine schriftliche Stellungnahme abgibt oder den juristischen Meinungsstand aufwändig recherchiert. Die Beantwortung allgemeiner Fragen oder die Empfehlung eines bestimmten Verhaltens ist bereits ausreichend, unabhängig von der Dauer, Länge oder Qualität der Fragen. 
          <br>
          <br>
          Erst wenn der Anwalt weiter für Sie tätig wird, also nach außen hin gegenüber Dritten in Erscheinung tritt, oder Unterlagen prüft, fallen weitere Gebühren an. Diese Tätigkeit des Rechtsanwalts ist nicht mehr von der Erstberatungsgebühr umfasst. Natürlich liegt die Entscheidung ganz bei Ihnen, ob Ihnen die Ersteinschätzung bereits genügt oder ob Sie ein weiteres Tätigwerden des Anwalts wünschen.
          <br>
          <br>
          Die Vergütung der Erstberatung wird in § 34 RVG (= Rechtsanwaltsvergütungsgesetz) geregelt. Das RVG stellt den abrechnungstechnischen Mindeststandard der Anwaltschaft dar. § 34 RVG sieht vor, dass die Erstberatungsgebühr für eine Beratung auf maximal 190,- Euro zzgl. Umsatzsteuer i.H.v. derzeit 19% beschränkt ist, wenn der Auftraggeber Verbraucher und kein Unternehmer ist. Diese Vorschrift soll Verbrauchern die Möglichkeit geben, transparent und sicher mit den anfallenden Kosten bei einer Erstberatung kalkulieren zu können.
          Unabhängig von den Regeln des RVG steht es Anwalt und MandantIn auch frei, eine anderslautende Vergütungsvereinbarung zu treffen.
          <br>
          <br>
          Ich biete Ihnen meine Erstberatungsleistung auf Basis des RVG an, also zum Preis von maximal 190,- Euro, wenn Sie VerbraucherIn sind. Die Erstberatung können Sie, gerade in Corona-Zeiten, ganz unkompliziert über Telefon, Videochat, Messenger oder Social Media in Anspruch nehmen, wenn Sie mit der Kommunikation über die benannten Medien einverstanden sind. Selbstverständlich ist auch ein persönliches Gespräch möglich. 
          <br>
          <br>
          <br>

          <b>Mythos Erstberatung: Gibt es eine kostenlose Erstberatung?</b>
          <br>
          <br>
          Das Gesetz sieht keine kostenlose Rechtsberatung vor. Juristen haben durch eine jahrelange Ausbildung ein spezifisches Fachwissen erworben und bieten die Anwendung dieses Spezialwissens auf Ihren konkreten Fall als vergütungspflichtige Leistung an. Entsprechend haben auch schon Gerichte entschieden, Mandanten könnten nicht einfach davon ausgehen, ein Anwalt würde seine Erstberatungsleistung gratis erbringen. Beim samstäglichen Brötchenkauf nimmt man schließlich auch nicht an, man bekäme Croissant und Brötchen von der Bäckerei geschenkt. Angebote von kostenloser Erstberatung sollten Sie aus diesem Grund zunächst immer auf Ihre Seriosität hin überprüfen!
          <br>
          <br>
          <br>    
          </p>    
          </div>
        </v-flex>
      </v-layout>
  </v-container>

  <hr class="my-2"/>

  <v-container>
    <v-layout wrap class="mt-5" align-center>
        <v-flex xs12>
          <div class="display-1 font-weight-bold" ref="baurecht">Privates Baurecht</div>
        </v-flex>
        <v-flex xs12 mt-5>
          <div
            class="body-1 mt-3"
            > 
            <p align="justify">
            Das private Baurecht regelt im Gegensatz zum öffentlichen Baurecht die Rechtsbeziehungen von Privaten zueinander in baurechtlichen Angelegenheiten. Beispiel: Sie möchten ein Haus bauen und beauftragen dafür ein Bauunternehmen. Das öffentliche Baurecht hingegen beschäftigt sich mit dem Verhältnis von Behörden, Ämtern bzw. der Verwaltung allgemein zu privaten Personen. Beispiel: Sie möchten ein Haus bauen und benötigen dafür die Erteilung einer Baugenehmigung. 
            <br><br>
            Da ich hauptberuflich als Syndikusrechtsanwalt in einem Bauunternehmen arbeite, ist das private Baurecht mein täglich Brot. Bauunternehmen werden von privaten, gewerblichen oder öffentlichen Auftraggebern beauftragt und führen die Leistungen selbst aus oder vergeben Sie an andere Bauunternehmen (Nachunternehmer) weiter. Die Baubranche hat ihre Eigenheiten und die sollte man kennen, ob nun als fleißiger Häuslebauer oder ehrgeiziger Unternehmer. 
            <br><br>
            Haben Sie Fragen zu Ihrem Verbraucherbauvertrag bei der Realisierung Ihres Traums vom Eigenheim? Gibt es Streitigkeiten mit dem Architekten? Fragen Sie sich, wie sich bei Störungen im Bauablauf am besten gegenüber Ihrem Auftraggeber verhalten sollen? Oder haben Sie eine Behinderungsanzeige/Bedenkenanmeldung erhalten und wissen nicht, wie nun zu tun ist? Dann melden Sie sich gerne bei mir! 
            <br><br>
            </p>
        </div>
          <br> 
        </v-flex>
      </v-layout>
  </v-container>

  <hr class="my-2"/>

  <v-container>
    <v-layout wrap class="mt-5" align-center>
      <v-flex xs12>
        <div class="display-1 font-weight-bold" ref="wirtschaftsrecht">Straßenverkehrszivilrecht</div>
      </v-flex>
      <v-flex xs12 mt-5>
        <div
          class="body-1 mt-3"
        >
        <p align="justify">
        Das Verkehrszivilrecht befasst sich mit den zivilrechtlichen Rechtsbeziehungen der
        Straßenverkehrsteilnehmer. Beispiel: Sie geraten unverschuldet in einen Unfall und ihr Auto
        wird dabei beschädigt. Der Schädiger gibt noch an Ort und Stelle des Unfalls zu, dass er
        alleine schuld am Unfall ist und teilt Ihnen seine Haftpflichtversicherung mit. Die
        Versicherung lehnt eine Komplettübernahme der Ihnen entstandenen Kosten mit der
        Begründung ab, aufgrund der Betriebsgefahr Ihres Kfz seien Haftungsquoten zu bilden, die
        eine vollständige Regulierung ausschließen.
        <br><br>
        So oder so ähnlich kann sich die Unfallschadenregulierung darstellen, mit der jede
        Autofahrerin statistisch gesehen einmal in ihrem Leben in Berührung kommt. Ich unterstütze
        Sie bei der außergerichtlichen Geltendmachung und Durchsetzung von
        Haftpflichtansprüchen. Erfahrungsgemäß erfolgt die versicherungstechnische Abwicklung
        eines Unfallschadens reibungsloser, wenn ein Anwalt frühzeitig eingebunden wird. Bitte
        beachten Sie, dass die Ihnen im Rahmen und im Zusammenhang mit dem Unfall
        entstehenden Rechtsanwaltskosten eine ersatzfähige Schadensposition darstellen können,
        die gegebenenfalls von der gegnerischen Haftpflichtversicherung zu begleichen sind.
        <br><br>
        </p>
        </div>
      </v-flex>
    </v-layout>
  </v-container>

  <hr class="my-2"/>

  <v-container>
    <v-layout wrap class="mt-5" align-center>
      <v-flex xs12>
        <div class="display-1 font-weight-bold" ref="irgendeinrecht">Forderungsmanagement</div>
      </v-flex>
      <v-flex xs12 mt-5>
        <div
          class="body-1 mt-3"
        >
        <p align="justify">
        Beinahe jede Unternehmerin kommt irgendwann einmal in die Situation, dass ein
        Vertragspartner offene Forderungen nicht begleicht. Säumige Schuldner können zu
        Liquiditätsengpässen führen, die sich vielleicht schon im Vorfeld hätten vermeiden lassen.
        Effektive Forderungsdurchsetzung ist das finanzielle Lebenselixier im unternehmerischen
        Bereich.
        <br><br>
        Gleichfalls kennen viele Verbraucherinnen das Gefühl, wenn man aus heiterem Himmel mit
        angeblich offenen Forderungen konfrontiert und zur Zahlung aufgefordert wird. Der Schreck
        ist oft groß und die Eintreibungsmethoden sind meist rustikal. Hier kann die Überprüfung der
        Berechtigung der geltend gemachten Ansprüche durch einen Anwalt ein sinnvoller erster
        Schritt zur Forderungsabwehr sein. Nicht jeder geltend gemachte Anspruch sollte am Ende
        des Tages auch bezahlt werden.
        <br><br>
        Gerne unterstütze ich Sie bei Ihrem außergerichtlichen Forderungsmanagement und berate
        über mögliche Handlungsstrategien. Nicht jeder Schuldner lässt sich die
        Zahlungsaufforderung eine Mahnung sein!
        <br><br><br>
         </p>
         </div>
      </v-flex>
    </v-layout>
  </v-container>
</div>
</template>

<script>
export default {
  watch: {
    '$route.query' : function(){
      if(this.$route.query.gebiet){
        window.scrollTo({ top: this.$refs[this.$route.query.gebiet].offsetTop - 75, behavior: 'smooth' });
      }
    }
  },
  mounted(){
    if(this.$route.query.gebiet){
      window.scrollTo({ top: this.$refs[this.$route.query.gebiet].offsetTop - 75, behavior: 'smooth' });
    }
  }
}
</script>
